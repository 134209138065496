import React from 'react'
import { css } from 'emotion'
import Colors from '../globals/colors'

const header = css({
  fontSize: `6rem`,
  color: Colors.blue,
})

const message = css({
  color: Colors.blue,
})

const tweaked = css({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `center`,
  width: `100%`,
  height: `100vh`
})

const link = css({
  textTransform: `uppercase`,
  color: Colors.white,
  transition: `color 0.3s ease`,
  '&:hover': {
    color: Colors.magenta
  }
})

const NotFoundPage = () => (
  <div className={tweaked}>
    <h1 className={header}>&times; 404 &times;</h1>
    <p className={message}>whatever you are looking for, is not here.</p>
    <p>
      <a href="/" className={link}>return to safety</a>
    </p>
  </div>
)

export default NotFoundPage
